// TODO mosk data
const dataEmpty = [
  {
    "_id":"60666c42cc7b410027a1a9b2",
    "name":"Собери здесь свой бургер",
    "type": "empty",
    "proteins": 0,
    "fat": 0,
    "carbohydrates": 0,
    "calories": 0,
    "price": 0,
    "image":"https://raw.githubusercontent.com/AndreSmrnv/YaP_react-burger/sprint-4/step-1/src/images/icon-burger.svg",
    "image_mobile":"https://raw.githubusercontent.com/AndreSmrnv/YaP_react-burger/sprint-4/step-1/src/images/icon-burger.svg",
    "image_large":"https://raw.githubusercontent.com/AndreSmrnv/YaP_react-burger/sprint-4/step-1/src/images/icon-burger.svg",
    "__v":0
  }
];

// TODO Экспорт данных для использования в компонентах
export default dataEmpty;
